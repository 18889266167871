import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "personal-titles"
    }}>{`Personal Titles`}</h1>
    <p>{`Capitalize a personal title only when it’s used directly before the individual’s name. Lowercase the
title when it’s used after their name.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Vice President of Communications Marina Moon danced until dawn.`}</li>
        <li parentName="ul">{`Bo Bounce, chief ping pong associate, loves a good bagel before a match.`}</li>
      </ul>
    </Do>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      